<template>
  <b-row class="lists page-discover">
    <b-col>
      <PageHeader :title="$t('general.discover.title')" />

      <b-row class="page-discover__sticky">
        <div class="page-discover__search">
          <CInput
            ref="input"
            type="text"
            name="searchCollections"
            input-mode="text"
            theme="comments"
            v-model="search"
            @input="searchPosts"
            :placeholder="$t('general.discover.searchPlaceholder')"
          />
        </div>
      </b-row>

      <div class="page-discover__trending-topics">
        <span class="page-discover__font page-discover__font--trending-title">
          {{ $t("general.discover.trendingTopics") }}
        </span>
      </div>

      <ul class="page-discover__list">
        <li
          v-for="topic in topicsList"
          :key="topic.hashtag.id"
          class="page-discover__topic"
        >
          <span class="page-discover__font page-discover__font--topic">
            {{ topic.hashtag.name }}
          </span>
        </li>
      </ul>

      <template v-if="popular && popular.length && !search">
        <div
          v-for="popular in popular"
          :key="popular.id"
          class="page-discover__hashtag-list"
        >
          <div class="page-discover__hashtag">
            <span
              class="page-discover__font page-discover__font--hashtag"
              v-if="popular.name"
            >
              {{ popular.name }}
            </span>

            <span class="page-discover__counter">
              <span class="page-discover__icon-wrapper">
                <CIcon name="video" class="page-discover__icon" />
              </span>

              <span
                class="page-discover__font page-discover__font--counter"
                v-if="popular.views_sum"
              >
                {{ nFormat(popular.views_sum) }}
              </span>
            </span>
          </div>

          <CSwiper
            v-if="popular.posts.length"
            :items="popular.posts"
            :slider-config="swiperConfig"
            class="page-discover__swiper"
            :static-view="false"
          >
            <template #slide="{ itemData } = {}">
              <div class="page-discover__card">
                <CLink
                  :to="itemData.postUrl"
                  class="page-discover__image-wrapper"
                >
                  <img
                    class="page-discover__image"
                    :src="itemData.thumbnail || '/mock/header.png'"
                  />
                </CLink>
              </div>
            </template>
          </CSwiper>
        </div>
      </template>

      <template v-else>
        <b-row :key="post.id" v-for="post in posts">
          <AppPost :post="post" />
        </b-row>
      </template>
    </b-col>
  </b-row>
</template>

<script>
import Post from "@/components/models/Post";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import PageHeader from "@/layout/PageHeader.vue";
import CIcon from "@/features/ui/CIcon.vue";
import CSwiper from "@/features/ui/CSwiper.vue";
import CLink from "@/features/ui/CLink.vue";
import AppPost from "@/features/containers/post/AppPost.vue";
import CInput from "@/features/ui/СInput.vue";

import { nFormat } from "@/tools/helpers";

import { mapGetters, mapMutations } from "vuex";

import { debounce } from "lodash";

const swiperConfig = {
  slidesPerView: 5.2,
  spaceBetween: 3,
  slidesOffsetBefore: 0,
  speed: 300,
  navigation: false,
  centeredSlides: false,
  observer: true,
};

export default {
  name: "PageDiscover",
  components: {
    AppPost,
    CLink,
    CSwiper,
    CIcon,
    CInput,
    PageHeader,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      swiperConfig,
      search: "",
      posts: [],
    };
  },
  mounted() {
    this.initState();
  },
  computed: {
    ...mapGetters({ discover: "discover/discover" }),

    topicsList() {
      return this.discover.trendingTopics;
    },

    popular() {
      return (
        this.discover?.popular?.map((item) => {
          const posts = item.posts.map((post) => {
            const newPost = new Post(post);
            let thumbnail = "";
            let postUrl = "";
            const media = newPost?.media?.[0] || [];
            const screenshot = media.screenshot;

            if (screenshot) {
              const { url = "", jwt = "" } = screenshot;
              thumbnail = `${url}?token=${jwt}`;
              postUrl = `/flow/${newPost.id}`;
            } else {
              thumbnail = media.url?.url || "/mock/header.webp";
              postUrl = newPost.url;
            }

            return { ...newPost, thumbnail, postUrl };
          });

          return { ...item, posts };
        }) || []
      );
    },
  },
  methods: {
    nFormat,
    ...mapMutations({
      setPosts: "discover/setPosts",
      updatePosts: "discover/updatePosts",
    }),
    initState() {
      this.initialLoadPost();
    },

    loadDiscover(successCallback) {
      if (this.requestInProgress) return;

      const url = new URL(`/discover`, process.env.VUE_APP_API_URL);

      if (this.search) {
        url.searchParams.set("q", this.search);
      }

      const requestUrl = url.pathname + url.search;

      return this.waitRequest(() => {
        return this.$get(
          requestUrl,
          successCallback,
          this.loadPostError,
          this.checkErrors
        );
      }).catch(this.checkErrors);
    },

    async initialLoadPost() {
      this.$showSpinner();

      this.loadDiscover(this.setCurrentPosts);
    },

    setCurrentPosts(data) {
      const posts = data.popular?.map((post) => new Post(post));
      this.setPosts({ ...data, posts });
    },

    updateCurrentPosts(data) {
      const posts = data.data?.map((post) => new Post(post));

      this.updatePosts({ posts });
    },

    loadPostError(errors) {
      console.log(errors);
    },

    searchPosts: debounce(function () {
      if (this.requestInProgress) return;

      const url = new URL(`/discover`, process.env.VUE_APP_API_URL);

      if (this.search) {
        url.searchParams.set("q", this.search);
      }

      const requestUrl = url.pathname + url.search;

      return this.waitRequest(() => {
        return this.$post(
          requestUrl,
          {},
          (data) => {
            this.posts = data.posts?.map((post) => new Post(post));
          },
          this.loadPostError,
          this.checkErrors
        );
      }).catch(this.checkErrors);
    }, 400),
  },
};
</script>

<style scoped lang="scss">
.lists {
  a {
    text-decoration: none;
    color: var(--dark);

    &:hover {
      color: var(--dark);
      text-decoration: none;
      background: var(--light);
    }
  }
}

.page-discover {
  &__header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-end;
  }

  &__search {
    width: 100%;
    margin: em(16) em(17) 0;
  }

  &__sticky {
    position: sticky;
    top: em(57);
    z-index: 1020;
    background-color: $white;
  }

  &__trenndig-topics {
    width: 100%;
    padding: em(12) em(17);
  }

  &__list {
    padding-left: em(16);
  }

  &__hashtag-list {
    margin-top: em(30);

    &:not(:first-of-type) {
      margin-top: em(26);
    }
  }

  &__topic {
    list-style: none;
    position: relative;

    margin-top: em(9);

    &:before {
      content: url("/svg/dot.svg");
      display: inline-block;
      position: absolute;
      top: em(-3);
      left: em(-11);
    }
  }

  &__group {
    margin: em(22) em(17);
  }

  &__hashtag {
    display: flex;
  }

  &__font {
    font-family: $font-default;
    font-style: normal;
    line-height: normal;

    &--topic {
      color: $app-gray-24;
      font-size: em(14);
      font-weight: 500;
    }

    &--hashtag,
    &--trending-title {
      color: $black;
      font-size: em(16);
      font-weight: 600;
    }

    &--counter {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-size: em(10);
      font-weight: 500;
    }
  }

  &__counter {
    margin-left: em(7);
    display: inline-flex;
    align-items: center;
    padding: em(3);
    background-color: rgba($black, 0.7);
    border-radius: em(5);
    color: $white;
    transition: color $time-normal $ease, background-color $time-normal $ease;
  }

  &__icon-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 1px;
  }

  &__icon {
    color: $white;
    width: em(13);
    height: em(13);
  }

  &__swiper {
    margin-top: em(9);
  }

  &__image-wrapper {
    width: 100%;
    height: 100%;
  }

  &__card {
    //width: em(87);
    height: em(133);
    border-radius: em(5);
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.collections-header-button {
  &__button {
    width: em(30);
    text-align: center;
  }

  &__icon {
    color: $app-gray-17;
    width: em(19);
    height: em(19);

    &--plus {
      width: em(14);
      height: em(14);
    }
  }
}

.user-tab {
  flex: 1;
  text-align: center;

  &__item {
    padding-top: em(15);
    padding-bottom: em(17);
    border-bottom: 1px solid $app-gray-7;

    &--active {
      padding-bottom: em(16);
      border-bottom: 2px solid $app-blue;
    }
  }

  &__font {
    color: $app-black;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.post-list {
  margin-right: -15px;
  margin-left: -15px;

  &__posts {
    margin-top: 1px;
  }
}
</style>
